import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {HplusRedirectionService} from '@vmi/utils';

import {HdmuiEmptyStatesModule} from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    templateUrl: './access-denied-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
        `,
    ],
    imports: [HdmuiEmptyStatesModule, TranslateModule],
})
export class AccessDeniedWrapperComponent {
    readonly #hplusRedirectionService = inject(HplusRedirectionService);

    onButtonClick(): void {
        this.#hplusRedirectionService.redirect();
    }
}
