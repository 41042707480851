import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AccessDeniedWrapperComponent} from './containers/access-denied-wrapper/access-denied-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: AccessDeniedWrapperComponent,
            },
        ]),
    ],
})
export default class FeatureAccessDeniedModule {}
